<template>
    <div>

        <HeroContentDetail v-if="data" :data="data" />

        <!-- <section class="section intro-section" v-if="data">
            <div class="container">
                <div class="column-wrapper">
                    <div class="column content-column">
                        <ul class="tags" v-if="data.fields.tags && data.fields.tags.length > 0
                            ">
                            <li v-for="item in data.fields.tags" :key="item.value">
                                {{ getEnumVal(item.value) }}
                            </li>
                        </ul>
                        <div class="content-wrapper" v-html="data.templateContentFields.contentFirst"></div>

                        <a class="to-full-story" @click.prevent="scrollMeTo('full-story')" href="#full-story/">Read
                            {{ data.templateContentFields.displayTitle }}’s
                            story</a>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="playlists-wrapper section video-collection"
            v-if="getRelatedPlaylistIds && getRelatedPlaylistIds.length > 0">
            <PlayList v-for="playListId in getRelatedPlaylistIds" :key="playListId" :playlistId="playListId">
                <template v-slot:header>
                    <h2 class="section-title">
                        Latest Videos
                    </h2>
                </template>
                <template v-slot:footer>
                    <div class="button-wrapper" v-if="data.enumerationValueId">
                        <router-link class="button" :to="'/all-videos/?Coaches+%26+Trainers=' +
                            data.enumerationValueId +
                            '#video-grid-collection-anchor'
                            ">See all videos</router-link>
                    </div>
                </template>
            </PlayList>
        </section>

        <section class="section main-content">
            <span class="anchor" id="full-story"></span>
            <div class="container">
                <div class="column-wrapper fullwidth">
                    <div class="column content" v-if="data">
                        <h2 class="column-title">
                            About {{ data.templateContentFields.displayTitle }}
                        </h2>
                        <div class="content-wrapper" v-html="data.templateContentFields.contentFirst"></div>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="section double-img" v-if="data">
            <div class="container">
                <div class="column-wrapper">
                    <div class="column image" v-if="data.templateContentMedia &&
                        data.templateContentMedia.mediaFirstImage
                        ">
                        <img :alt="data.templateContentMedia.mediaFirstImage.title
                            " :src="data.templateContentMedia.mediaFirstImage.url" />
                    </div>
                    <div class="column image" v-if="data.templateContentMedia &&
                        data.templateContentMedia.mediaSecondImage
                        ">
                        <img :alt="data.templateContentMedia.mediaSecondImage.title
                            " :src="data.templateContentMedia.mediaSecondImage.url" />
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</template>

<script>
// import { fetchMediaList } from "@streampac.io/chef_sp_1";
// @import component
// import HeroVideo from "@/components/HeroVideo";
// import VideoSliderBar from "@/components/VideoSliderBar";

import HeroContentDetail from "@/components/HeroContentDetail";

import PlayList from "@/components/PlayList";

// import JourneyFilterCollection from "@/components/JourneyFilterCollection";

import {
    // fetchVideoContentList,
    fetchTemplateContentDetailsBySlug,
    // fetchCmsMedia,
    // fetchPlaylistById,
} from "@streampac.io/chef_sp_1";

export default {
    name: "home",
    inject: ["isAuthenticated"],
    data() {
        return {
            getRelatedPlaylistIds: null,
            getRelatedPlaylistItems: [],
            paging: {
                pageNumber: 1,
                pageSize: 6,
            },
            //mediaAsset: null,
            data: null,
        };
    },
    components: {
        HeroContentDetail,
        PlayList,
        // JourneyFilterCollection,
    },
    created() {
        // let filter = new URLSearchParams();
        // filter.set("name", "bert");
        // history.pushState(filter.toString(), "", "?" + filter);
        this.getPageContent();
    },
    methods: {
        getPageContent() {
            if (this.$route.params.slug !== "") {
                fetchTemplateContentDetailsBySlug(
                    this.isAuthenticated,
                    this.$route.params.slug,
                    "?flattenFields=true"
                ).then((response) => {
                    this.data = response;

                    console.log(response);

                    if (
                        response.relatedContents &&
                        response.relatedContents !== ""
                    ) {
                        let playlistIds = [];

                        response.relatedContents.forEach((related) => {
                            if (related.entityType == "Playlist") {
                                playlistIds.push(related.contentId);
                            }
                        });

                        this.getRelatedPlaylistIds = playlistIds;
                    }

                    // if (
                    // 	response.templateContentFields.mediaFirstImage &&
                    // 	response.templateContentFields.mediaFirstImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaFirstImage,
                    // 		"mediaFirstImage"
                    // 	);
                    // }

                    // if (
                    // 	response.templateContentFields.mediaSecondImage &&
                    // 	response.templateContentFields.mediaSecondImage !== ""
                    // ) {
                    // 	this.resolveCmsMedia(
                    // 		response.templateContentFields.mediaSecondImage,
                    // 		"mediaSecondImage"
                    // 	);
                    // }
                });
            }
        },
        stripHtml(val) {
            const strippedString = val.replace(/(<([^>]+)>)/gi, "");

            return strippedString;
        },
        // resolveCmsMedia(val, name) {
        // 	if (val !== "") {
        // 		fetchCmsMedia(this.isAuthenticated, val).then((response) => {
        // 			this[name] = response;
        // 		});
        // 	}
        // },

        scrollMeTo() {
            //refName in function
            // var element = this.$refs[refName];
            // var top = element.offsetTop - 130;

            var anchor = document.querySelector("#full-story");
            anchor.scrollIntoView({
                behavior: "smooth",
            });
        },
        getEnumVal(val) {
            const enums = this.$store.state.getEnumLookup;

            if (enums && enums.length > 0) {
                const enumFilter = enums.filter((item) => item.id === val);

                if (enumFilter && enumFilter.length > 0) {
                    return enumFilter[0].enumValue;
                }
            }

            return "";
        },
        hasHistory() {
            return window.history.length > 2;
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.playlists-wrapper.section {
    section {
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
}
</style>
